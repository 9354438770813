var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "login",
      class: _vm.loginType == 1 ? "loginImg1" : "loginImg2"
    },
    [
      _vm._m(0),
      _c("div", { staticClass: "login-rt-top" }, [
        _c("div", { staticClass: "login-rt-top-content" }, [
          _c(
            "div",
            {
              staticClass: "login_type",
              on: {
                click: function($event) {
                  _vm.changeLoginType(4)
                }
              }
            },
            [_vm._v("经销商登录")]
          ),
          _c(
            "div",
            {
              staticClass: "login_type",
              on: {
                click: function($event) {
                  _vm.changeLoginType(3)
                }
              }
            },
            [_vm._v("供应商登录")]
          ),
          _c(
            "div",
            {
              staticClass: "login_type",
              on: {
                click: function($event) {
                  _vm.changeLoginType(2)
                }
              }
            },
            [_vm._v("商家登录")]
          ),
          _c(
            "div",
            {
              staticClass: "login_type",
              on: {
                click: function($event) {
                  _vm.changeLoginType(1)
                }
              }
            },
            [_vm._v("平台登录")]
          ),
          _c(
            "div",
            { staticClass: "rt-top" },
            [
              _c(
                "el-popover",
                { attrs: { placement: "top-start", trigger: "hover" } },
                [
                  _c("img", {
                    attrs: { src: require("../assets/youfangke_code.jpg") }
                  }),
                  _c("div", { staticStyle: { "text-align": "center" } }, [
                    _vm._v("微信扫一扫")
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "login_code",
                      attrs: { slot: "reference" },
                      slot: "reference"
                    },
                    [_vm._v('"云数e家"微信小程序')]
                  )
                ]
              )
            ],
            1
          )
        ])
      ]),
      _c(
        "div",
        { staticClass: "login-box" },
        [
          _c(
            "p",
            {
              staticClass: "title",
              class: _vm.loginType == 1 ? "" : "title_other"
            },
            [_vm._v(_vm._s(_vm.loginTitle))]
          ),
          _vm.loginType == 1
            ? [
                _c("el-input", {
                  staticClass: "login-input",
                  attrs: { placeholder: "请输入账号", clearable: "" },
                  on: { change: _vm.changeAccount },
                  nativeOn: {
                    keyup: function($event) {
                      if (
                        !("button" in $event) &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.goToSubmit($event)
                    }
                  },
                  model: {
                    value: _vm.form.account,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.form,
                        "account",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "form.account"
                  }
                }),
                _c("el-input", {
                  staticClass: "login-input login-password",
                  attrs: {
                    type: "password",
                    placeholder: "请输入登录密码",
                    clearable: ""
                  },
                  on: { change: _vm.changePassword },
                  nativeOn: {
                    keyup: function($event) {
                      if (
                        !("button" in $event) &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.goToSubmit($event)
                    }
                  },
                  model: {
                    value: _vm.form.password,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.form,
                        "password",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "form.password"
                  }
                }),
                _c(
                  "div",
                  {
                    staticClass: "login-housekeeper",
                    on: {
                      click: function($event) {
                        _vm.isHousekeeperLogin()
                      }
                    }
                  },
                  [
                    _c("img", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.isHousekeeper,
                          expression: "!isHousekeeper"
                        }
                      ],
                      attrs: { src: require("../assets/weixuan.png") }
                    }),
                    _c("img", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isHousekeeper,
                          expression: "isHousekeeper"
                        }
                      ],
                      attrs: { src: require("../assets/xuanzhong.png") }
                    }),
                    _c("span", { staticStyle: { color: "#888888" } }, [
                      _vm._v("普通管理员（房管员）登录")
                    ])
                  ]
                )
              ]
            : _vm._e(),
          _vm.loginType != 1
            ? [
                _c("div", { staticClass: "login-method" }, [
                  _c(
                    "span",
                    {
                      staticClass: "login-method-password",
                      class: _vm.loginMethod == 1 ? "active" : "",
                      on: {
                        click: function($event) {
                          _vm.selectLoginMethod(1)
                        }
                      }
                    },
                    [_vm._v("密码登录")]
                  ),
                  _c(
                    "span",
                    {
                      staticClass: "login-method-vercode",
                      class: _vm.loginMethod == 2 ? "active" : "",
                      on: {
                        click: function($event) {
                          _vm.selectLoginMethod(2)
                        }
                      }
                    },
                    [_vm._v("验证码登录")]
                  )
                ]),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.loginMethod == 1,
                        expression: "loginMethod==1"
                      }
                    ]
                  },
                  [
                    _c(
                      "el-input",
                      {
                        staticClass: "login-input_other",
                        attrs: { placeholder: "请输入账号", clearable: "" },
                        on: { change: _vm.changeAccount },
                        nativeOn: {
                          keyup: function($event) {
                            if (
                              !("button" in $event) &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.goToSubmit($event)
                          }
                        },
                        model: {
                          value: _vm.form.account,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.form,
                              "account",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "form.account"
                        }
                      },
                      [
                        _c("template", { slot: "prepend" }, [
                          _c("i", {
                            staticClass: "fa fa-user-o",
                            staticStyle: { "font-size": "19px" }
                          })
                        ])
                      ],
                      2
                    ),
                    _c(
                      "el-input",
                      {
                        staticClass: "login-input_other login-password",
                        attrs: {
                          type: "password",
                          placeholder: "请输入登录密码",
                          clearable: ""
                        },
                        on: { change: _vm.changePassword },
                        nativeOn: {
                          keyup: function($event) {
                            if (
                              !("button" in $event) &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.goToSubmit($event)
                          }
                        },
                        model: {
                          value: _vm.form.password,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.form,
                              "password",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "form.password"
                        }
                      },
                      [
                        _c("template", { slot: "prepend" }, [
                          _c("i", {
                            staticClass: "fa fa-unlock-alt",
                            staticStyle: { "font-size": "22px" }
                          })
                        ])
                      ],
                      2
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.loginMethod == 2,
                        expression: "loginMethod==2"
                      }
                    ]
                  },
                  [
                    _c(
                      "el-input",
                      {
                        staticClass: "login-input_other",
                        attrs: { placeholder: "请输入手机号码", clearable: "" },
                        on: { change: _vm.changePhone },
                        nativeOn: {
                          keyup: function($event) {
                            if (
                              !("button" in $event) &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.goToSubmit($event)
                          }
                        },
                        model: {
                          value: _vm.form.phone,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.form,
                              "phone",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "form.phone"
                        }
                      },
                      [
                        _c("template", { slot: "prepend" }, [
                          _c("i", {
                            staticClass: "fa fa-tablet",
                            staticStyle: { "font-size": "22px" }
                          })
                        ])
                      ],
                      2
                    ),
                    _c(
                      "el-input",
                      {
                        staticClass: "login-input_other login-vercode",
                        attrs: { placeholder: "请输入验证码", clearable: "" },
                        on: { change: _vm.changeVercode },
                        nativeOn: {
                          keyup: function($event) {
                            if (
                              !("button" in $event) &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.goToSubmit($event)
                          }
                        },
                        model: {
                          value: _vm.form.vercode,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.form,
                              "vercode",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "form.vercode"
                        }
                      },
                      [
                        _c("template", { slot: "prepend" }, [
                          _c("i", {
                            staticClass: "fa fa-unlock-alt",
                            staticStyle: { "font-size": "22px" }
                          })
                        ])
                      ],
                      2
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "login-getCode",
                        attrs: { loading: _vm.getCodeLoading },
                        on: { click: _vm.send }
                      },
                      [
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.showTite,
                                expression: "!showTite"
                              }
                            ]
                          },
                          [_vm._v(_vm._s(this.count) + "s")]
                        ),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.showTite,
                                expression: "showTite"
                              }
                            ],
                            staticStyle: { color: "#30BAC1" }
                          },
                          [_vm._v("获取验证码")]
                        )
                      ]
                    )
                  ],
                  1
                ),
                _c("div", { staticClass: "login-settled" }, [
                  _c(
                    "span",
                    {
                      on: {
                        click: function($event) {
                          _vm.merchantsOccupancy(_vm.loginType)
                        }
                      }
                    },
                    [_vm._v("没有账号，立即入住")]
                  )
                ])
              ]
            : _vm._e(),
          _c(
            "el-button",
            {
              staticClass: "login-btn",
              attrs: { type: "text" },
              on: { click: _vm.goToSubmit }
            },
            [_vm._v("\n      登录\n    ")]
          ),
          _vm.isShowFormAlt
            ? _c("el-alert", {
                staticClass: "error-alert",
                attrs: {
                  title: _vm.alertTitle,
                  type: "error",
                  "show-icon": "",
                  closable: false
                }
              })
            : _vm._e()
        ],
        2
      ),
      _c("div", { staticClass: "copyright" }, [
        _vm._v("\n    深圳市威富视界 版权所有 IPC备案：粤ICP备17007206号-1"),
        _c("br"),
        _vm._v("\n    邮箱 : wfyundata@wfyundata.com"),
        _c("br"),
        _vm._v("\n    版本: " + _vm._s(_vm.getVersion()) + "\n  ")
      ]),
      _c("Verify", {
        ref: "verify",
        attrs: {
          mode: "pop",
          captchaType: "blockPuzzle",
          imgSize: { width: "330px", height: "155px" }
        },
        on: { success: _vm.login }
      }),
      _c("Verify", {
        ref: "verify1",
        attrs: {
          mode: "pop",
          captchaType: "blockPuzzle",
          imgSize: { width: "330px", height: "155px" }
        },
        on: { success: _vm.verifiedBy }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "login-logo" }, [
      _c("img", {
        staticStyle: { "margin-top": "30px" },
        attrs: { src: require("../assets/logo1.png") }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }