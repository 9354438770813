<template>
  <div class="login" :class="loginType == 1 ? 'loginImg1' : 'loginImg2'">
    <div class="login-logo">
      <img style="margin-top:30px;" src="../assets/logo1.png">
    </div>
    <div class="login-rt-top">
      <div class="login-rt-top-content">
        <div class="login_type" @click="changeLoginType(4)">经销商登录</div>
        <div class="login_type" @click="changeLoginType(3)">供应商登录</div>
        <div class="login_type" @click="changeLoginType(2)">商家登录</div>
        <div class="login_type" @click="changeLoginType(1)">平台登录</div>
        <div class="rt-top">
          <!-- <span @click="contractLoad">合同下载</span> -->
          <!-- <span class="applet">“云数e家”微信小程序</span> -->
          <el-popover placement="top-start" trigger="hover">
            <img src="../assets/youfangke_code.jpg" />
            <div style="text-align: center">微信扫一扫</div>
            <div slot="reference" class="login_code">"云数e家"微信小程序</div>
          </el-popover>
        </div>
      </div>

    </div>
    <div class="login-box">
      <p class="title" :class="loginType == 1 ? '' : 'title_other'">{{loginTitle}}</p>
      <template v-if="loginType == 1" >
         <el-input @keyup.enter.native="goToSubmit" v-model.trim="form.account" @change="changeAccount" placeholder="请输入账号"
          class="login-input" clearable>
        </el-input>
        <el-input class="login-input login-password" type="password" @change="changePassword"
          @keyup.enter.native="goToSubmit" v-model.trim="form.password" placeholder="请输入登录密码" clearable>
        </el-input>
        <div class="login-housekeeper" @click="isHousekeeperLogin()">
          <img v-show="!isHousekeeper" src="../assets/weixuan.png" />
          <img v-show="isHousekeeper" src="../assets/xuanzhong.png" />
            <span style="color: #888888;">普通管理员（房管员）登录</span>
        </div>
      </template>
      <template v-if="loginType != 1" >
        <div class="login-method">
          <span class="login-method-password" :class="loginMethod == 1 ? 'active' : ''" @click="selectLoginMethod(1)">密码登录</span>
          <span class="login-method-vercode" :class="loginMethod == 2 ? 'active' : ''" @click="selectLoginMethod(2)">验证码登录</span>
        </div>
        <!-- 密码登录 -->
        <div v-show="loginMethod==1">
          <el-input @keyup.enter.native="goToSubmit" v-model.trim="form.account" @change="changeAccount" placeholder="请输入账号"
            class="login-input_other" clearable>
             <template slot="prepend">
              <i class="fa fa-user-o" style="font-size: 19px"></i>
             </template>
          </el-input>
          <el-input class="login-input_other login-password" type="password" @change="changePassword"
            @keyup.enter.native="goToSubmit" v-model.trim="form.password" placeholder="请输入登录密码" clearable>
            <template slot="prepend">
              <i class="fa fa-unlock-alt" style="font-size: 22px"></i>
            </template>
          </el-input>
        </div>
        <!-- 验证码登录 -->
        <div v-show="loginMethod==2">
          <el-input @keyup.enter.native="goToSubmit" v-model.trim="form.phone" @change="changePhone" placeholder="请输入手机号码"
            class="login-input_other" clearable>
             <template slot="prepend">
              <i class="fa fa-tablet" style="font-size: 22px"></i>
             </template>
          </el-input>
          <el-input class="login-input_other login-vercode" @change="changeVercode"
            @keyup.enter.native="goToSubmit" v-model.trim="form.vercode" placeholder="请输入验证码" clearable>
            <template slot="prepend">
              <i class="fa fa-unlock-alt" style="font-size: 22px"></i>
            </template>
          </el-input>
          <el-button class="login-getCode" :loading="getCodeLoading" @click="send">
                <span v-show="!showTite">{{ this.count }}s</span>
                <span v-show="showTite" style="color: #30BAC1">获取验证码</span>
            </el-button>
        </div>
        <div class="login-settled"><span @click="merchantsOccupancy(loginType)">没有账号，立即入住</span></div>
      </template>
     
      <el-button class="login-btn" type="text" @click="goToSubmit">
        登录
      </el-button>
      <el-alert v-if="isShowFormAlt" :title="alertTitle" class="error-alert" type="error" show-icon :closable="false">
      </el-alert>
    </div>
    <div class="copyright">
      深圳市威富视界 版权所有 IPC备案：粤ICP备17007206号-1<br>
      邮箱 : wfyundata@wfyundata.com<br>
      版本: {{getVersion()}}
    </div>
    <Verify @success="login" :mode="'pop'" :captchaType="'blockPuzzle'" :imgSize="{ width: '330px', height: '155px' }"
      ref="verify"></Verify>
  </div>
</template>
<script>
  import Vue from "vue";
  const TIME_COUNT = 60; //更改倒计时时间
  const reg = /^1[3-9](\d){9}$/;
  export default {
    data() {
      return {
        form: {
          account: "",
          password: "",
          phone: '',
          vercode: '',
        },
        isShowFormAlt: false,
        alertTitle: "",
        cacheAccount: '',
        cachePassword: '',
        cachePhone: '',
        cacheVercode: '',
        loginType: 1, //1--平台 2--商家 3--供应商
        loginTitle: '威富云数智慧管理平台',
        isHousekeeper: false,
        loginMethod: 1,//1--密码登录；2--验证码登录
        getCodeLoading: false,
        smsCode: '',
        showTite: true, // 初始启用按钮
        count: "", // 初始化次数
        timer: null,
      };
    },
    created() {
      
      this.loginMethod = 1;
      console.log("login created!");
      // #ifdef H5
      document.addEventListener("touchmove", function (e) {
        e.preventDefault();
      }, false); //禁止页面滑动
      // #endif
    },
    activated() {
      
    },
    destroyed() {
      
    },
    deactivated() {
      console.log('deactivated')
      // 初始化定时器和错误标志 
      this.isShowFormAlt = false;
      this.initSmsCodeData()
    },
    methods: {
      login(params) {
        this.onSubmit(params.captchaVerification);
      },
      goToSubmit() {
        if(this.loginMethod == 2) {
          if (this.cachePhone) {
            this.form.phone = this.cachePhone;
          }
          if (this.cacheVercode) {
            this.form.vercode = this.cacheVercode;
          }
          this.isShowFormAlt = false;
          if (!this.form.phone || !this.form.vercode) {
            this.alertTitle = "请输入正确的手机号码和验证码";
            this.isShowFormAlt = true;
            return false;
          }
        }else {
          if (this.cacheAccount) {
            this.form.account = this.cacheAccount;
          }
          if (this.cachePassword) {
            this.form.password = this.cachePassword;
          }
          this.isShowFormAlt = false;
          if (!this.form.account || !this.form.password) {
            this.alertTitle = "请输入正确的账号密码";
            this.isShowFormAlt = true;
            return false;
          }
          if (this.form.account == this.form.password) {
            this.alertTitle = "账号和密码不能相同";
            this.isShowFormAlt = true;
            return false;
          }
        }
        
        //等保需求，先不删除
        // if (!this.$pattern.account.val.test(this.form.account)) {
        //    this.alertTitle="账号只能包含大小写字母与数字,长度6-16位";
        //    this.isShowFormAlt = true;
        //   return false;
        // }
        //  if (this.$pattern.Az09es.val.test(this.form.password)) {
        //   this.alertTitle="密码只能包含大小写字母、数字、英文符号";
        //    this.isShowFormAlt = true;
        //   return false;
        // }
        // if (!this.$pattern.passWord.val.test(this.form.password)) {
        //    this.alertTitle="密码必须最少包含字母、数字、1个大写字母、1个小写字母,长度8-16位";
        //    this.isShowFormAlt = true;
        //   return false;
        // }
        this.$refs.verify.refresh();
        this.$refs.verify.show();
      },
      changeAccount(e) {
        this.cacheAccount = e;
      },
      changePassword(e) {
        this.cachePassword = e;
      },
      changePhone(e) {
        this.cachePhone = e;
      },
      changeVercode(e) {
        this.cacheVercode = e;
      },
      onSubmit(captchaVerification) {

        let dto = {
          // username: this.AES.encrypt(this.form.account),
          // password: this.AES.encrypt(this.form.password),
          // username: this.form.account,
          // password: this.form.password,
          captchaVerification: captchaVerification,
        };
        let url = "/user-service/user/webLogin";
        if (Vue.config.BASE_URL == 'https://apartment-preapi.wfyundata.com' || Vue.config.BASE_URL ==
          'https://apartment-api.wavewisdom.com') {
          if(this.loginMethod == 2) {
            dto.mobile = this.AES.encrypt(this.form.phone);
            dto.authCode = this.AES.encrypt(this.form.vercode);
          }else {
            dto.username = this.AES.encrypt(this.form.account);
            dto.password = this.AES.encrypt(this.form.password);
          }
          
        } else {
         if(this.loginMethod == 2) {
            dto.mobile = this.form.phone;
            dto.authCode = this.form.vercode;
          }else {
            dto.username = this.form.account;
            dto.password = this.form.password;
          }
        }
        if(this.loginType == 2 || this.loginType == 3 || this.loginType == 4) {
          if(this.loginMethod == 2) {
            url = "user-service/mall/v1/web/auth-code/login";
          }else {
            url = "user-service/mall/v1/web/login";
          }
        }
        if (this.loginType == 2) {
          // 商家登录需增加入参
          dto.roleId = 10;
        } else if(this.loginType == 3){
          // 供应商登录需增加入参
          dto.roleId = 12;
        }else if(this.loginType == 4){
          // 经销商登录需增加入参
          dto.roleId = 13;
        }else {
          if (dto.roleId) {
            delete dto.roleId
          }
        }
        if(this.isHousekeeper) {
          dto.roleType = '6' //普通管理员（房管员）登录
        }
        this.isShowFormAlt = false;
        let myOpt =  {
          isUseResponse: true,
          isUnToken: true,
          isNotShowError: true,
          isNotShowCatchError: true,
          contentType: "application/x-www-form-urlencoded"
        };
        if(this.loginMethod == 2) {
           myOpt =  {
            isUseResponse: true,
            isUnToken: true,
            isNotShowError: true,
            isNotShowCatchError: true,
          };
        }
        this.post(url, dto,myOpt).then(res => {
          if (res.data.code == 0) {
            this.cache.set('loginRole',dto.roleType)
            this.cache.setLS("userInfo", res.data.data);
            localStorage.setItem("userId", res.data.data.userId);
            this.cache.setLS("token", res.data.data.accessToken);
            localStorage.setItem("phoneNum", res.data.data.phoneNum);
            this.cache.setLS('loginType', this.loginType); //登录类型  1-平台  2-商家
            if (res.data.data.loginCheckEnabled == 1) {
              this.$router.push({
                path: "/secondCheck",
                query: {
                  key: res.data.data.key,
                  account: this.form.account
                }
              });
            } else {
              this.cache.setLS('lastTime', new Date().getTime())
              // localStorage.setItem("lastTime", new Date().getTime());
              this.$message({
                message: res.data.msg,
                type: "success"
              });
              if(this.loginMethod == 2) {
                res.data.data.account = this.form.phone;
              }else {
                res.data.data.account = this.form.account;
              }
              this.cache.setLS("userInfo", res.data.data);
              localStorage.setItem("userId", res.data.data.userId);
              this.cache.setLS("token", res.data.data.accessToken);
              localStorage.setItem("phoneNum", res.data.data.phoneNum);
              if (res.data.data.flag === 0) {
                this.$router.push("/main/userSet/password");
                return;
              }
              if (this.cache.getLS("userInfo")["roleType"] == "1") {
                this.$router.push("/main/dataOverview/list");
              }else if(this.cache.getLS("loginType") == 2 || this.cache.getLS("loginType") == 3 || this.cache.getLS("loginType") == 4) {//商家/供应商/经销商登录后默认跳转至商户信息页面
                this.$router.push("/main/merchantManage/merchantInfo/detail");
              }else {
                this.getFirstPath();
              }
            }

          } else {
            this.$message({
              message: res.data.msg,
              type: "warning"
            });
          }
        }).catch(err => {
          this.$message({
            message: err.data ? err.data.msg : '登录失败，请稍后重试',
            type: "warning"
          });
        });
      },
      getFirstPath() {
        this.menuList = [
          //  {
          //   icon: "fa fa-bell-o",
          //   name: "消息管理",
          //   menuId: 86,
          //   path: "/main/notice/list",
          //   group: [
          //     {
          //       name: "消息列表",
          //       menuId: 87,
          //       path: "/main/notice/list"
          //     }
          //   ]
          // },
          // {
          //   icon: "fa fa-home",
          //   name: "首页",
          //   menuId: 1,
          //   path: "main/log/list",
          //   group: []
          // },
          {
            icon: "fa fa-bar-chart",
            name: "数据概览",
            menuId: 165,
            path: "/main/dataOverview/list",
            group: [{
                name: "首页",
                menuId: 166,
                path: "/main/dataOverview/list"
              },{
                name: "用户",
                menuId: 167,
                path: "/main/dataUser/list"
              },
              {
                name: "房源",
                menuId: 168,
                path: "/main/dataRoom/list"
              },
              {
                name: "设备",
                menuId: 169,
                path: "/main/dataDevice/list"
              },
              {
                name: "售货",
                menuId: 170,
                path: "/main/dataShop/list"
              },
            ]
          },
          {
            icon: "fa fa-building",
            name: "房管中心",
            menuId: 2,
            path: "/main/apartment/list",
            group: [{
                name: "房源列表",
                menuId: 10,
                path: "/main/apartment/list"
              },
              {
                name: "房型列表",
                menuId: 11,
                path: "/main/house/list"
              },
              {
                name: "房间列表",
                menuId: 12,
                path: "/main/room/list"
              }
            ]
          },
          {
            icon: "fa fa-flash",
            name: "设备管理",
            menuId: 3,
            path: "/main/auth-device/list",
            group: [{
                name: "网关列表",
                menuId: 117,
                path: "/main/gateway/list"
              }, {
                name: "门禁列表",
                menuId: 66,
                path: "/main/AccessControl/list"
              },
              {
                name: "人证机列表",
                menuId: 13,
                path: "/main/auth-device/list"
              },
              {
                name: "绑定记录",
                menuId: 14,
                path: "/main/lock/list"
              },
              {
                name: "分离式门禁列表",
                menuId: 68,
                path: "/main/separateAccessControl/list"
              },
              {
                name: "蓝牙公钥列表",
                menuId: 69,
                path: "/main/BluetoothKey/list"
                // path: "/main/smartMeter/list"
              },
              {
                name: "电子门禁列表",
                menuId: 76,
                path: "/main/electronicAccessControl/list"
                // path: "/main/smartMeter/list"
              },
              {
                name: "智能电表列表",
                menuId: 70,
                path: "/main/smartMeter/list"
              },
              {
                name: "智能水表列表",
                menuId: 71,
                path: "/main/smartWaterMeter/list"
              },
              {
                name: "认证记录",
                menuId: 20,
                path: "/main/authentication/list"
              },
              {
                name: "智能设备",
                menuId: 62,
                path: "/main/ied/list"
              },
              {
                name: "通行记录",
                menuId: 67,
                path: "/main/PassRecord/list"
              },
              {
                name: "报警器列表",
                menuId: 109,
                path: "/main/alarm/list"
              },
              {
                name: "报警记录",
                menuId: 110,
                path: "/main/alarmRecord/list"
              },
              // {
              //   name: "门禁记录",
              //   menuId: 51,
              //   path: "/main/guard/list"
              // }
            ]
          },
          {
            icon: "fa fa-file-text-o",
            name: "订单管理",
            menuId: 4,
            path: "/main/order/list",
            group: [{
                name: "订单列表",
                menuId: 15,
                path: "/main/order/list"
              },
              {
                name: "退款列表",
                menuId: 64,
                path: "/main/Refundlist/list"
              }
            ]
          },
          {
            icon: "fa fa-yen",
            name: "财务管理",
            menuId: 5,
            path: "/main/finance/overview",
            group: [{
                name: "财务概览",
                menuId: 16,
                path: "/main/finance/overview"
              },
              {
                name: "销售统计",
                menuId: 17,
                path: "/main/sale/list"
              },
              {
                name: "对账管理",
                menuId: 18,
                path: "/main/statement/list"
              },
              {
                name: "提款记录",
                menuId: 58,
                path: "/main/record/list"
              },
              {
                name: "转账记录",
                menuId: 59,
                path: "/main/money/list"
              }
            ]
          },
          // {
          //   icon: 'fa fa-user-plus',
          //   name: '房东管理',
          //   menuId: 24,
          //   path: '/main/landlord/list',
          //   group: []
          // },
          {
            icon: "fa fa-group",
            name: "用户管理",
            menuId: 6,
            path: "/main/tenant/list",
            group: [
              {
                name:"用户列表",
                menuId: 160,
                path: "/main/userList/list"
              },
              {
                name: "房客列表",
                menuId: 19,
                path: "/main/tenant/list"
              },
              {
                name: '商户列表',
                menuId: 89,
                path: '/main/landlord/list'
              },
            ]
          },
          {
            icon: "fa fa-book",
            name: "租约管理",
            menuId: 104,
            path: "/main/leaseManagement/list",
            group: [{
              name: "租约列表",
              menuId: 105,
              path: "/main/leaseManagement/list"
            },
            {
              name: "退房列表",
              menuId: 65, 
              path: "/main/CheckOut/list"
            },
            {
              name: "约看列表",
              menuId: 51,
              path: "/main/date/list"
            },
            {
              name: "批量排房",
              menuId: 134,
              path: "/main/batchrRangement/list"
            },{
              name: "批量退房",
              menuId: 180,
              path: "/main/batchrCheckOut/list"
            },  ]
          },
          // {
          //   icon: "fa fa-tripadvisor",
          //   name: "约看管理",
          //   menuId: 50,
          //   path: "/main/date/list",
          //   group: []
          // },
          {
            icon: "fa fa-unlock-alt",
            name: "访问控制",
            menuId: 25,
            path: "/main/account/list",
            group: [{
                name: "房管员列表",
                menuId: 26,
                path: "/main/account/list"
              },
              {
                name: "权限管理",
                menuId: 27,
                path: "/main/jurisdiction/list"
              }
            ]
          },
          {
            icon: "fa fa-file-text-o",
            name: "合同管理",
            menuId: 52,
            path: "/main/contract/list",
            group: [{
                name: "合同列表",
                menuId: 53,
                path: "/main/contract/list"
              },
              {
                name: "合同模板",
                menuId: 75,
                path: "/main/ContractTemplate/list"
              }
            ]
          },
          {
            icon: "fa fa-list",
            name: "售货管理",
            menuId: 80,
            path: "/main/cargoController/list",
            group: [{
                name: "商家管理",
                menuId: 119,
                path: "/main/merchantManage/merchant/tabList"
              },{
                name: "供应商管理",
                menuId: 119,
                path: "/main/merchantManage/supplier/tabList"
              }, {
                name: "经销商管理",
                menuId: 162,
                path: "/main/merchantManage/dealer/tabList"
              },{
                name: "会员列表",
                menuId: 113,
                path: "/main/membersManagement/list"
              },
              {
                name: "货管员列表",
                menuId: 81,
                path: "/main/cargoController/list"
              },
              {
                name: "门店管理",
                menuId: 106,
                path: "/main/groupManagement/list"
              },
              {
                name: "货柜管理",
                menuId: 82,
                path: "/main/containerManagement/list"
              },
              {
                name: "品类管理",
                menuId: 83,
                path: "/main/categoryManagement/list"
              },
              {
                name: "商品管理",
                menuId: 84,
                path: "/main/commodityManagement/list"
              },
              {
                name: "仓库管理",
                menuId: 116,
                path: "/main/storehouse/list"
              },
              {
                name: "报损记录",
                menuId: 112,
                path: "/main/lossReportRecord/list"
              },
              {
                name: "售货订单",
                menuId: 85,
                path: "/main/salesOrder/list"
              },
              {
                name: "实时售卖",
                menuId: 115,
                path: "/main/goodsOnSale/list"
              },
              {
                name: "退款订单",
                menuId: 111,
                path: "/main/refundOrder/list"
              },
              {
                name: "商家收益记录",
                menuId: 121,
                path: "/main/merchantManage/revenueRecords/list"
              },
              {
                name: "供应商收益记录",
                menuId: 140,
                path: "/main/supplierRevenueRecord/list"
              },
              {
                name: "提现记录",
                menuId: 122,
                path: "/main/merchantManage/witchdrawalRecords/list"
              },
              {
                name: "充值管理",
                menuId: 142,
                path: "/main/rechargeManagement/list"
              },
              {
                name: "发货管理",
                menuId: 120,
                path: "/main/shippingManage/list"
              },
              {
                name: "商家信息",
                menuId: 118,
                path: "/main/merchantManage/merchantInfo/detail"
              },
              {
                name: "开票记录",
                menuId: 107,
                path: "/main/billingRecord/list"
              }, {
                name: "营销配置",
                menuId: 114,
                path: "/main/marketingConfig/list"
              },
               {
                name: "活动任务管理",
                menuId: 159,
                path: "/main/activeTask/list"
              },
              {
                name: "银行信息管理",
                menuId: 151,
                path: "/main/bankManagement/list"
              },
              {
                name: "意见反馈",
                menuId: 88,
                path: "/main/containerFeedback/list"
              }
            ]
          },
          {
            icon: "fa fa-exchange",
            name: "电车换电",
            menuId: 143,
            path: "/main/tram/user/list",
             group: [{
                name: "用户列表",
                menuId: 144,
                path: "/main/tram/user/list"
              },
              {
                name: "商品管理",
                menuId: 145,
                path: "/main/tram/commodityManagement/list"
              },
              {
                name: "订单列表",
                menuId: 148,
                path: "/main/tram/order/list"
              },
              {
                name: "换电记录",
                menuId: 149,
                path: "/main/tram/powerChangeRecord/list"
              },
              {
                name: "告警记录",
                menuId: 150,
                path: "/main/tram/alarmRecord/list"
              }]
          },
          {
            icon: "fa fa-university",
            name: "校园管理",
            menuId: 172,
            path: "/main/schoolManagement/order/list",
            group:[{
                name: "订单列表",
                menuId: 174,
                path: "/main/schoolManagement/order/list"
            },{
                name: "学校管理",
                menuId: 173,
                path: "/main/schoolManagement/school/list"
            }],
            
          },
          {
            icon: "fa fa-cog",
            name: "系统管理",
            menuId: 7,
            path: "/main/user/list",
            group: [{
                name: "系统用户",
                menuId: 21,
                path: "/main/user/list"
              },
              {
                name: "房源配置",
                menuId: 177,
                path: "/main/apartment/config"
              },
              {
                name: "参数设置",
                menuId: 101,
                path: "/main/parameter/list"
              },
              {
                name: "广告管理",
                menuId: 102,
                path: "/main/adManagement/list"
              },
              {
                name: "广告位管理",
                menuId: 103,
                path: "/main/adSpaceManagement/list"
              },
              {
                name: "角色管理",
                menuId: 22,
                path: "/main/role/list"
              },
              {
                name: "菜单管理",
                menuId: 23,
                path: "/main/menu/list"
              },
              // {
              //   name: "房东管理",
              //   menuId: 24,
              //   path: "/main/landlord/list"
              // },
              {
                name: "房东信息",
                menuId: 61,
                path: "/main/information/detail/show/0"
              },
              {
                name: "学校信息",
                menuId: 176,
                path: "/main/schoolInfo/list"
                },
			{
                name: "导出中心",
                menuId: 171,
                path: "/main/exportCenter/list"
              },
              {
                menuId: 8,
                name: "登录日志",
                path: "/main/log/list"
              },
              {
                menuId: 108,
                name: "操作日志",
                path: "/main/operationLog/list"
              },
              {
                menuId: 63,
                name: "意见反馈",
                path: "/main/feedback/list"
              }
            ]
          }
        ];
        // 房东的数据概览
        if(this.cache.getLS("userInfo")["roleType"] == "2") {
          this.menuList.forEach(item => {
            if(item.menuId == 165) {
              item.path = '/main/landlorDataOverview/list'
              item.group = [{
                name: "首页",
                menuId: 166,
                path: "/main/landlorDataOverview/list"
              },]
            }
          })
        }
        let menuConfig = this.cache.getLS("userInfo").userMenuInfos;
        let allIdList = [];
        if (menuConfig && menuConfig.length) {
          menuConfig.forEach((item) => {
            allIdList.push(item.menuId);
            if (item.childMenus.length) {
              item.childMenus.forEach(data => {
                allIdList.push(data.menuId);
              });
            }
          });
        }
        console.log(allIdList);
        let obj = this.menuList;
        let num = 0;
        this.menuList.forEach((item) => {
          // if (allIdList.indexOf(item.menuId) != -1) {
          //   if(num==0){
          //      console.log(item);
          //     this.$router.push(item.path)
          //   }
          //   num++;
          // }
          item.group.forEach(data => {
            if (allIdList.indexOf(data.menuId) != -1) {
              if (num == 0) {
                console.log(item);
                this.$router.push(data.path)
              }
              num++;
            }
          });
        });
      },
      contractLoad() {
        this.$router.push("/downLoad");
      },
      changeLoginType(val) {
        this.loginMethod = 1;// 默认密码登录
        this.initSmsCodeData()
        this.isShowFormAlt = false
        this.form.phone = '';
        this.form.vercode = '';

        this.loginType = val;
        if (val == 1) {
          this.loginTitle = '威富云数智慧管理平台'
        } else if(val == 2){
          this.loginTitle = '商家管理平台'
        } else if(val == 3){
          this.loginTitle = '供应商管理平台'
        }else if(val == 4){
          this.loginTitle = '经销商管理平台'
        }
       
      },
      
      isHousekeeperLogin() {
        this.isHousekeeper = !this.isHousekeeper;
      },
      // 商家/供应商/经销商入驻
      merchantsOccupancy(val) {
        // value 1-商家 2-供应商 3-经销商
        let value = val -1
        this.$router.push("/occupancy/" + value);
      },
      selectLoginMethod(val) {
        this.loginMethod = val
        // 切换登录方式 清除定时器
        if(val == 1) {
          this.initSmsCodeData()
          this.form.phone = '';
          this.form.vercode = '';
        }
        this.isShowFormAlt = false
      },
      initSmsCodeData() {
        this.showTite = true;
        if(this.timer) {
          clearInterval(this.timer); // 清除定时器
        }
        this.timer = null;
      },
      // 获取验证码
      send() {
        if(!this.form.phone) {
         return this.$message({
              message: '请输入手机号码',
              type: 'warning',
          })
        }else {
          if(!reg.test(this.form.phone)) {
            return this.$message({
              message: '手机号码格式不正确',
              type: 'warning',
            })
          }
        }
            this.timer = null;
            if(!this.showTite) return

            let dto = {
                mobile: this.form.phone
            };
            if (!this.timer) {
                this.getCodeLoading = true
                this.post("user-service/weChat/sendMobileCode", dto).then(res => {
                    console.log(res);
                    this.getCodeLoading = false
                    this.count = TIME_COUNT;
                    this.showTite = false;
                    this.timer = setInterval(() => {
                        if (this.count > 0 && this.count <= TIME_COUNT) {
                            this.count--;
                        } else {
                            this.showTite = true;
                            clearInterval(this.timer); // 清除定时器
                            this.timer = null;
                        }
                    }, 1000);
                    this.$message({
                        message: '发送成功！',
                        type: 'success',
                    })
                }).catch(err => {
                    this.getCodeLoading = false
                })
            }
        },
    }
  };
</script>
<style lang="scss">
  .loginImg1 {
    background: url("../assets/login_bk_new.png") no-repeat center top;
    background-size: cover;
  }
  .loginImg2 {
    background: url("../assets/beijing.png") no-repeat center top;
    background-size: cover;
  }
  .login {
    position: relative;
    height: 100%;

    // background-image: url("../assets/login_bk_new.png");

    .login-logo {
      position: fixed;
      top: 0;
      left: 98px;
    }

    .login-rt-top-content {
      display: flex;
    }

    .login_type {
      padding-right: 30px;
      cursor: pointer;
    }

    .login-rt-top {
      position: absolute;
      top: 46px;
      right: 49px;
      // width: 620px;
      // height: 100%;

      .rt-top {
        font-size: 16px;
        // color: #888888;
        text-align: right;
        cursor: pointer;
        // margin-top: 48px;
        // margin-right: 300px;

        .applet {
          margin-left: 65px;
        }
      }

    }

    .login-box {
      position: absolute;
      // top: 306px;
      top: 31.2%;
      right: 357px;
      width: 538px;
      background: #FFFFFF;
      box-shadow: 0px 3px 20px 1px rgba(5, 37, 39, 0.1);
      border-radius: 8px;

      .title {
        font-size: 36px;
        font-weight: bold;
        color: #333333;
        line-height: 1;
        margin-top: 57px;
        margin-bottom: 39px;
        text-align: center;
      }

      

      .login-input {
        width: 412px;
        margin-left: 63px;

        .el-input__inner {
          height: 50px;
          line-height: 1;
          background: #FAFAFA;
          border: 1px solid #C6C6C6;
          // font-size: 18px;
          box-sizing: border-box;
          font-size: 14px;
          // color: #CCCCCC;
        }

        .el-input__inner:focus {
          border: 1px solid #30BAC1;
        }
      }

      .login-input_other {
        width: 412px;
        margin-left: 63px;

        .el-input__inner {
          height: 50px;
          line-height: 1;
          background: #FAFAFA;
          border: 1px solid #C6C6C6;
          // font-size: 18px;
          box-sizing: border-box;
          font-size: 14px;
          // color: #CCCCCC;
        }

        .el-input__inner:focus {
          border: 1px solid #30BAC1;
        }
      }

      .title_other {
        margin-top: 44px;
        margin-bottom: 30px;
      }

      .login-method {
        margin-left: 63px;
        margin-bottom: 15px;
        .login-method-password {
          margin-right: 30px;
          padding-bottom: 2px;
          cursor: pointer;
        }
        .login-method-vercode {
          padding-bottom: 2px;
          cursor: pointer;
        }
        .active {
          border-bottom: 1px solid #30BAC1;
        }
      }

      .login-housekeeper img {
        position: relative;
        top: 4px;
        margin-left: 63px;
        margin-top: 6px;
        margin-right: 4px;
        cursor: pointer;
      }

      .login-password {
        margin-top: 25px;
      }

      .login-vercode {
        margin-top: 25px;
        width: 300px;
      }

      .login-getCode {
        width: 112px;
        height: 50px;
      }

      .login-settled {
        position: relative;
        top: 4px;
        margin-left: 63px;
        margin-top: 6px;
        color: #30BAC1;
        span {
          cursor: pointer;
        }
      }

      .login-btn {
        margin-left: 62px;
        margin-top: 33px;
        margin-bottom: 33px;
        width: 414px;
        height: 55px;
        line-height: 1;
        background: #30BAC1;
        border: 1px solid #FFFFFF;
        font-size: 18px;
        font-weight: 400;
        color: #FFFFFF;
      }

      .error-alert {
        position: relative;
        top: 10px;
        width: 414px !important;
        margin-left: 62px !important;
      }
    }

    .copyright {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 68px;
      padding-top: 10px;
      background: #FFFFFF;
      font-size: 12px;
      color: #666666;
      line-height: 18px;
      text-align: center;
    }
  }

  @media screen and (min-width:501px) and (max-width:1370px) {
    .login {
      .login-box {
        top: 50%;
        left: 50%;
        right: 0;
        transform: translate(-50%, -50%);
      }
    }
  }


  @media only screen and (max-width: 500px) {
    .login .login-rt-top .rt-top {
      display: none;
    }

    .login .login-box {
      position: absolute;
      top: 160px;
      right: 2%;
      width: 96%;
      height: 350px;
    }

    .login .login-box .title {
      font-size: 20px;
      margin-left: 0;
      text-align: center;
    }

    .login .login-box .login-input {
      width: 80%;
      margin-left: 10%;
    }

    .login .login-box .login-btn {
      width: 80%;
      margin-left: 10%;
    }
  }

  /* #ifdef H5 */
  html,
  body {
    // font-size: 16px;
    touch-action: pan-y;
  }

  /* #endif */
</style>